import { Property, PropertyProps } from "shared-ts-property";
import React, { FC } from "react";
import { DateTimeISO } from "@airmont/shared/ts/types";
import { DateTime } from "luxon";
import {
  _throw,
  IllegalStateError,
  notNullOrUndefined,
} from "@airmont/shared/ts/utils/core";
import { useTranslation } from "react-i18next";

export type EstimatedSweepDatePropertyProps = Omit<
  PropertyProps,
  "name" | "label" | "type"
> & {
  value?: DateTimeISO | null;
};

export const EstimatedSweepDateProperty: FC<EstimatedSweepDatePropertyProps> = (
  props
) => {
  const dateTime = notNullOrUndefined(
    props.value,
    (it) => DateTime.fromISO(it) ?? _throw(new IllegalStateError("value"))
  );
  const { t: tFireflySharedDomain } = useTranslation(
    "firefly-shared-ts-domain"
  );

  return (
    <Property
      {...props}
      name={"flue.estimatedSweepDate"}
      label={tFireflySharedDomain("Upcoming Sweep")}
      type={
        dateTime == null
          ? undefined
          : dateTime.year === 10000
          ? "string"
          : "date"
      }
      value={dateTime == null ? null : dateTime.year === 10000 ? "∞" : dateTime}
    />
  );
};
