import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import { DateTime, Duration, Interval } from "luxon";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { GridActionsColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { streetAddressGridValueGetter } from "../shared/streetAddressGridValueGetter";
import { DurationDisplay } from "@airmont/shared/ts/ui/time";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import {
  BatteryLevelUtils,
  ChimneySensorCableStatusEnum,
  ChimneySensorStatusEnum,
  resolveChimneySensorCableStatusIcon,
  resolveChimneySensorStatusColor,
  resolveChimneySensorStatusIcon,
  RsrpLevelDictionary,
  RsrqLevelDictionary,
  RssiLevelDictionary,
  SinrLevelDictionary,
  useFireflySharedTsDomainTranslation,
} from "@airmont/firefly/shared/ts/domain";
import {
  _throw,
  IllegalStateError,
  notNullOrUndefined,
} from "@airmont/shared/ts/utils/core";
import { Stack, Tooltip } from "@mui/material";
import * as React from "react";
import { useMemo } from "react";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { clsx } from "clsx";
import { pathValueGetter } from "shared/ts/ui/x-data-grid";

export const useSensorStatusTableColumns = (args: {
  onViewOnClick: (params: GridRowParams<ChimneySensorStatusDto>) => void;
  onEditOnClick: (params: GridRowParams<ChimneySensorStatusDto>) => void;
}): GridColDef<ChimneySensorStatusDto>[] => {
  const { onViewOnClick, onEditOnClick } = args;
  const { t } = useFireflySharedTsDomainTranslation();

  return useMemo(() => {
    const actions = (
      params: GridRowParams
    ): Array<React.ReactElement<GridActionsCellItemProps>> => {
      const actions: Array<React.ReactElement<GridActionsCellItemProps>> = [];
      actions.push(
        <GridActionsCellItem
          icon={<VisibilityRoundedIcon />}
          label="Se"
          onClick={() => onViewOnClick(params)}
        />
      );
      return actions;
    };

    return [
      {
        field: "streetAddress",
        headerName: "Gateadresse",
        width: 200,
        pinnable: false,
        hideable: false,
        valueGetter: streetAddressGridValueGetter,
      },
      {
        field: "streetAddress.houseSection",
        headerName: "Benr.",
        description: "Bruksenhetsnr",
        width: 75,
        sortable: false,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "flueName",
        headerName: "Røykløp",
        sortable: false,
        pinnable: false,
      },
      {
        field: "postalAddress.code",
        headerName: "Postnr.",
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "postalAddress.place",
        headerName: "Poststed",
        sortable: false,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "cadastre.cadastralUnit",
        headerName: "Gnr.",
        description: "Gårdsnummer",
        type: "string",
        width: 75,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "cadastre.propertyUnit",
        headerName: "Bnr.",
        description: "Bruksnummer",
        type: "string",
        width: 75,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "cadastre.leaseNumber",
        headerName: "F.nr.",
        description: "Festenummer",
        type: "string",
        width: 75,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "id",
        headerName: "Serienr.",
        sortable: true,
        pinnable: false,
      },
      {
        field: "model",
        headerName: "Modell",
        sortable: true,
        pinnable: false,
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        align: "center",
        sortable: true,
        pinnable: false,
        renderCell: (params) => {
          const status: ChimneySensorStatusEnum =
            ChimneySensorStatusEnum[params.row.status];
          const Icon = resolveChimneySensorStatusIcon(status);
          const color = resolveChimneySensorStatusColor(status);
          return (
            <Tooltip title={t(`ChimneySensorStatus.${status}`)}>
              <Icon color={color} sx={{ verticalAlign: "middle" }} />
            </Tooltip>
          );
        },
      },
      {
        field: "cableStatus",
        headerName: "Kabelstatus",
        type: "string",
        align: "center",
        sortable: true,
        pinnable: false,
        renderCell: (params) => {
          const status: ChimneySensorCableStatusEnum =
            ChimneySensorCableStatusEnum[params.row.cableStatus];
          const Icon = resolveChimneySensorCableStatusIcon(status);
          return (
            <Tooltip title={t(`ChimneySensorCableStatus.${status}`)}>
              <Stack
                direction={"row"}
                sx={{ m: "auto", width: "fit-content", alignItems: "center" }}
              >
                {Icon}{" "}
                <sub>{`${t(`ChimneySensorCableStatus.short.${status}`)}`}</sub>
              </Stack>
            </Tooltip>
          );
        },
      },
      {
        field: "retired",
        headerName: "Pensjonert",
        type: "boolean",
        align: "center",
        sortable: true,
        pinnable: false,
      },
      {
        field: "uptime",
        headerName: "Oppetid",
        type: "string",
        align: "right",
        sortable: true,
        pinnable: false,
        renderCell: (params) => {
          const duration = Duration.fromObject({ seconds: params.row.uptime });
          return (
            <DurationDisplay
              duration={duration}
              maxUnits={1}
              layout={SizeClass.Compact}
            />
          );
        },
      },
      {
        field: "lastHeard",
        headerName: "Sist hørt fra",
        type: "string",
        width: 130,
        align: "right",
        sortable: true,
        pinnable: false,
        renderCell: (params) => {
          const now = DateTime.local();
          const duration = notNullOrUndefined(params.value, (it) =>
            Interval.fromDateTimes(DateTime.fromISO(it), now).toDuration()
          );
          if (duration == null) {
            return null;
          }

          return (
            <DurationDisplay
              duration={duration}
              maxUnits={1}
              layout={SizeClass.Compact}
              suffix={"siden"}
            />
          );
        },
      },
      {
        field: "batteryLevel",
        headerName: "Batterinivå",
        type: "string",
        align: "center",
        width: 100,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row) =>
          t(`BatteryLevel.${BatteryLevelUtils.getStatus(row.board.voltage)}`),
      },
      {
        field: "board.voltage",
        headerName: "Batteriets volt",
        type: "number",
        width: 150,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "signal.strength",
        headerName: "RSSI",
        description: "Radio: Received Signal Strength Indicator",
        type: "number",
        width: 100,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
        cellClassName: (params) => {
          const value = params.value;
          return clsx("radio-signal", RssiLevelDictionary(value));
        },
      },
      {
        field: "signal.refReceivedPower",
        headerName: "RSRP",
        description: "Radio: Reference Signal Received Power",
        type: "number",
        width: 100,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
        cellClassName: (params) => {
          const value = params.value;
          return clsx("radio-signal", RsrpLevelDictionary(value));
        },
      },
      {
        field: "signal.refReceivedQuality",
        headerName: "RSRQ",
        description: "Radio: Reference Signal Received Quality",
        type: "number",
        width: 100,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
        cellClassName: (params) => {
          const value = params.value;
          return clsx("radio-signal", RsrqLevelDictionary(value));
        },
      },
      {
        field: "signal.interferenceNoiseRatio",
        headerName: "SINR",
        description: "Radio: Signal to Interference Noise Ratio",
        type: "number",
        width: 100,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
        cellClassName: (params) => {
          const value = params.value;
          return clsx("radio-signal", SinrLevelDictionary(value));
        },
      },
      {
        field: "comment",
        headerName: "Kommentar",
        type: "string",
        width: 100,
        sortable: true,
        pinnable: false,
      },
      {
        field: "tags",
        headerName: "Emneknagger",
        type: "string",
        width: 100,
        sortable: true,
        pinnable: false,
      },
      {
        field: "audit.created",
        headerName: "Opprettet",
        type: "string",
        width: 160,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(value, row, column, apiRef, (value) =>
            notNullOrUndefined(value, (it) => {
              const dateTime =
                DateTime.fromISO(it) ??
                _throw(new IllegalStateError("audit.created"));
              return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
            })
          ),
      },
      {
        field: "audit.createdBy",
        headerName: "Opprettet av",
        type: "string",
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "audit.modified",
        headerName: "Endret",
        type: "string",
        width: 160,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(value, row, column, apiRef, (value) =>
            notNullOrUndefined(value, (it) => {
              const dateTime =
                DateTime.fromISO(it) ??
                _throw(new IllegalStateError("audit.modified"));
              return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
            })
          ),
      },
      {
        field: "audit.modifiedBy",
        headerName: "Endret av",
        type: "string",
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "actions",
        headerName: "Handlinger",
        type: "actions",
        width: 100,
        pinnable: false,
        hideable: false,
        getActions: (params: GridRowParams) => actions(params),
      } as GridActionsColDef,
    ];
  }, [onViewOnClick, t]);
};
