import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Marker as ClusterMarker,
  MarkerClusterer,
} from "@googlemaps/markerclusterer";
import { useMap } from "@vis.gl/react-google-maps";
import { Feature, Point } from "geojson";
import { FeatureMarkers } from "./FeatureMarkers";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface ClusteredFeatureMarkersProps<TFeature extends Feature<Point>> {
  features: Array<TFeature>;
  render: (args: {
    feature: TFeature;
    openMarkerInfoWindow: boolean;
    onMarkerInfoWindowOpen: (open: boolean, feature: TFeature) => void;
    setMarkerRef?: (marker: ClusterMarker | null, key: string) => void;
  }) => JSX.Element;
}

export const ClusteredFeatureMarkers = <TFeature extends Feature<Point>>(
  props: ClusteredFeatureMarkersProps<TFeature>
) => {
  const { features } = props;
  const [markers, setMarkers] = useState<{ [key: string]: ClusterMarker }>({});

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  if (map == null) {
    throw new IllegalStateError("Map not loaded yet");
  }
  const clusterer = useMemo(() => {
    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers, features]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback(
    (marker: ClusterMarker | null, key: string) => {
      setMarkers((markers) => {
        if ((marker && markers[key]) || (!marker && !markers[key]))
          return markers;

        if (marker) {
          return { ...markers, [key]: marker };
        } else {
          const { [key]: _, ...newMarkers } = markers;

          return newMarkers;
        }
      });
    },
    []
  );

  return (
    <FeatureMarkers
      features={features}
      setMarkerRef={setMarkerRef}
      render={props.render}
    />
  );
};
