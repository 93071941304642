import React, { FC } from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { Deck } from "@airmont/shared/ts/ui/deck";
import { useTranslation } from "react-i18next";
import { InfoWindow } from "@vis.gl/react-google-maps";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { SensorStatusFeature } from "./SensorStatusFeature";
import { SensorStatusInfoWindowCard } from "./SensorStatusInfoWindowCard";

export interface ChimneySensorInfoWindowProps {
  feature: SensorStatusFeature;
  marker: google.maps.marker.AdvancedMarkerElement | null;
  onClose: () => void;
  onView?: (flue: ChimneySensorStatusDto) => void;
}

export const ChimneySensorInfoWindow: FC<ChimneySensorInfoWindowProps> = (
  props
) => {
  const { feature, marker, onClose } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");

  return (
    <InfoWindow anchor={marker} onCloseClick={onClose} headerDisabled={true}>
      <Paper elevation={12}>
        <Deck
          layout={"compact"}
          previousButtonProps={{
            tooltip: t("Previous Flue"),
          }}
          nextButtonProps={{
            tooltip: t("Next Flue"),
          }}
        >
          {feature.properties.sensors.map((sensor, index) => {
            return (
              <Stack>
                {feature.properties.sensors.length > 1 && (
                  <Typography
                    color={"text.secondary"}
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {t("{{number}} of {{total}} sensors", {
                      number: index + 1,
                      total: feature.properties.sensors.length,
                    })}
                  </Typography>
                )}
                <SensorStatusInfoWindowCard
                  key={index}
                  sensor={sensor}
                  onView={props.onView}
                  onClose={onClose}
                />
              </Stack>
            );
          })}
        </Deck>
      </Paper>
    </InfoWindow>
  );
};
