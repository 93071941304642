import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import { SensorStatusFeature } from "./SensorStatusFeature";
import { ChimneySensorStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { SensorFeatureUtils } from "./SensorFeatureUtils";
import {
  Map,
  MapController,
  MapLoadingProgress,
  useLatLngBounds,
} from "shared-ts-ui-react-google-maps";
import { ChimneySensorInfoWindow } from "./ChimneySensorInfoWindow";
import { notUndef } from "@airmont/shared/ts/utils/core";

export interface SensorStatusMapProps {
  pageUrl: string;
  municipality?: string;
  selected?: string;
  data: Array<ChimneySensorStatusDto>;
  width?: number;
  height?: number;
}

export const SensorStatusMap: FC<SensorStatusMapProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [features, setFeatures] = useState<Array<SensorStatusFeature>>([]);
  useEffect(() => {
    const features = SensorFeatureUtils.groupSensorsOnSameLocation(
      SensorFeatureUtils.sensorsToFeatures(props.data)
    );
    setFeatures(features);
  }, [props.data]);

  const latLngBounds = useLatLngBounds(features);
  const defaultBounds = useMemo(
    () => notUndef(latLngBounds, (it) => it.toJSON()),
    [latLngBounds]
  );
  const defaultCenter = useMemo(
    () => notUndef(latLngBounds, (it) => it.getCenter().toJSON()),
    [latLngBounds]
  );

  const handleView = (sensor: ChimneySensorStatusDto) => {
    navigate(`${props.pageUrl}/view/${sensor.id}`);
  };

  return defaultBounds !== undefined ? (
    <Map
      palette={theme.palette.mode}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
      defaultBounds={defaultBounds}
      backgroundColor={theme.palette.background.default}
      defaultCenter={defaultCenter}
    >
      <MapController
        features={features}
        renderInfoWindow={(props) => (
          <ChimneySensorInfoWindow {...props} onView={handleView} />
        )}
      />
    </Map>
  ) : (
    <MapLoadingProgress />
  );
};

export default React.memo(SensorStatusMap);
