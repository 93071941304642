import React, { FC, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material";
import {
  FlueStatusDto,
  FlueStatusGeoFeature,
  flueStatusToGeoFeature,
  groupFeaturesOnSameLocation,
} from "@airmont/firefly/chimney-insights/ts/domain";

import {
  Map,
  MapController,
  MapLoadingProgress,
  useLatLngBounds,
} from "shared-ts-ui-react-google-maps";
import { notUndef } from "@airmont/shared/ts/utils/core";
import { FlueInfoWindow } from "./FlueInfoWindow";

export interface FlueStatusMapProps {
  pageUrl: string;
  municipality?: string;
  selected?: string;
  data: Array<FlueStatusDto>;
  width?: number;
  height?: number;
}

export const FlueStatusMap: FC<FlueStatusMapProps> = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [features, setFeatures] = useState<Array<FlueStatusGeoFeature>>([]);

  useEffect(() => {
    const features = groupFeaturesOnSameLocation(
      props.data.map((it) => flueStatusToGeoFeature(it))
    );
    setFeatures(features);
  }, [props.data]);

  const latLngBounds = useLatLngBounds(features);
  const defaultBounds = useMemo(
    () => notUndef(latLngBounds, (it) => it.toJSON()),
    [latLngBounds]
  );
  const defaultCenter = useMemo(
    () => notUndef(latLngBounds, (it) => it.getCenter().toJSON()),
    [latLngBounds]
  );

  const handleView = (flue: FlueStatusDto) => {
    navigate(`${props.pageUrl}/view/${flue.id}`);
  };

  return defaultBounds !== undefined ? (
    <Map
      palette={theme.palette.mode}
      mapTypeId={google.maps.MapTypeId.ROADMAP}
      defaultBounds={defaultBounds}
      backgroundColor={theme.palette.background.default}
      defaultCenter={defaultCenter}
      isFractionalZoomEnabled={false}
    >
      <MapController
        features={features}
        renderInfoWindow={(props) => (
          <FlueInfoWindow {...props} onView={handleView} />
        )}
      />
    </Map>
  ) : (
    <MapLoadingProgress />
  );
};
