import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  TextField,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import { StringParam, useQueryParam } from "use-query-params";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { SearchInput } from "@airmont/firefly/shared/ts/ui";
import {
  FlueStatusDto,
  FlueStatusQueryDto,
  useFlueStatusQuery,
  useFlueStatusQueryClient,
  useMunicipalityContext,
} from "@airmont/firefly/chimney-insights/ts/domain";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import { FitContainerPane, PanelLayout } from "@airmont/shared/ts/ui/layout";
import { usePaperElevation } from "@airmont/shared/ts/ui/elevation";
import { FlueStatusTable } from "./FlueStatusTable";
import { FlueDialog } from "../flue/FlueDialog";
import { FlueId } from "@airmont/firefly/shared/ts/domain";
import { useNotify } from "@airmont/shared/ts/ui/notification";
import { useQueryRows } from "shared/ts/ui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { FlueStatusMap } from "./map/FlueStatusMap";
import {
  GoogleMapApiProvider,
  WaitForGoogleApisLoaded,
} from "shared-ts-ui-react-google-maps";

export const FlueStatusPage = () => {
  const theme = useTheme();
  const componentSize = useComponentSizeFromDense();
  const { t } = useTranslation("firefly-chimney-insights-ts-pages");
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const paperElevation = usePaperElevation();
  const notify = useNotify();
  const navigate = useNavigate();
  const { search: searchParams } = useLocation();
  const { idParam } = useParams();
  const { municipality } = useMunicipalityContext();
  const [zipCode, setZipCodeParam] = useQueryParam("zipCode", StringParam);
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [searchSubmit, setSearchSubmit] = useState(0);

  const [displayFlue, setDisplayFlue] = useState<FlueStatusDto | undefined>(
    undefined
  );

  const queryInput = useFlueStatusQuery({
    municipality: municipality,
    postalCode: zipCode,
    search: search,
  });
  const {
    rows,
    selectedRowId,
    replaceRow,
    deleteRow,
    setSelectedRowId,
    query,
    handleRowsChange,
  } = useQueryRows<FlueId, FlueStatusDto, FlueStatusQueryDto>(queryInput);
  const indexOfDisplayed = useMemo(() => {
    return rows.findIndex((it) => it.id === idParam);
  }, [idParam, rows]);
  const queryClient = useFlueStatusQueryClient();
  const rowsWithGeoLocations = useMemo<Array<FlueStatusDto>>(
    () => rows.filter((it) => it.geoLocation != null),
    [rows]
  );
  useEffect(() => {
    const doEffect = async () => {
      if (idParam === undefined) {
        setDisplayFlue(undefined);
      } else {
        const flueToDisplay = rows.find((it) => it.id === idParam);
        if (flueToDisplay !== undefined) {
          setDisplayFlue(flueToDisplay);
        } else {
          try {
            const flueToDisplay = await queryClient.getById(idParam);
            setDisplayFlue(flueToDisplay);
          } catch (e) {
            notify.warning({ error: e });
          }
        }
      }
    };
    doEffect();
  }, [idParam, rows, queryClient, notify]);

  const handleGoPrevious = () => {
    if (indexOfDisplayed > 0) {
      const previousIndexToDisplay = indexOfDisplayed - 1;
      const previousToDisplay = rows[previousIndexToDisplay];
      navigate(
        `view/${previousToDisplay.id}?${new URLSearchParams(
          searchParams
        ).toString()}`
      );
    }
  };

  const handleGoNext = () => {
    if (indexOfDisplayed < rows.length - 1) {
      const nextIndexToDisplay = indexOfDisplayed + 1;
      const nextToDisplay = rows[nextIndexToDisplay];
      navigate(
        `view/${nextToDisplay.id}?${new URLSearchParams(
          searchParams
        ).toString()}`
      );
    }
  };

  const handleSearchSubmit = () => {
    setSearchSubmit((prev) => prev + 1);
  };

  const handleFilterPostalCodeKeyUp = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && event.target instanceof HTMLInputElement) {
      setZipCodeParam(
        event.target.value.isNotEmpty() ? event.target.value : null
      );
      handleSearchSubmit();
    }
  };

  const handleSearchChange = (value: string) => {
    setSearch(value.isNotBlank() ? value : null);
    handleSearchSubmit();
  };

  const handleSelectedRow = (rowId: FlueId | undefined) => {
    setSelectedRowId(rowId);
  };

  const handleViewRequested = (flueStatus: FlueStatusDto) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    if (urlSearchParams.get("mainTab") === "sensor") {
      urlSearchParams.set("mainTab", "overview");
    }
    navigate(`view/${flueStatus.id}?${urlSearchParams.toString()}`);
  };

  const handleEditRequested = (flueStatus: FlueStatusDto) => {
    navigate(`edit/${flueStatus.id}${searchParams}`);
  };

  const handleDisplayFlueDialogClose = () => {
    navigate(`.${searchParams}`);
  };

  const handleFlueUpdated = (update: FlueStatusDto) => {
    replaceRow(update);
  };

  const handleFlueDeleted = (deleted: FlueStatusDto) => {
    deleteRow(deleted.id);
    navigate(`.${searchParams}`);
  };

  return (
    <>
      <AppPage
        $key={"ChimneyStatusPage"}
        name={"ChimneyStatusPage"}
        hideName
        mainPaneProps={{
          paperElevation: 0,
          disableGutters: true,
        }}
        mainPane={
          <PanelLayout
            resizable={true}
            autoSaveId={"firefly:ChimneyStatusPage"}
            panelProps={[
              {
                title: t("Map"),
                resizableOptions: {
                  defaultSize: 40,
                },
                style: {
                  display: "flex",
                },
              },
              {
                title: t("Table"),
                resizableOptions: {
                  defaultSize: 60,
                },
                style: {
                  display: "flex",
                },
              },
            ]}
            panels={[
              <Paper
                key={1}
                elevation={3}
                sx={{ flex: 1, position: "relative" }}
              >
                <FitContainerPane>
                  {({ width, height }) => (
                    <GoogleMapApiProvider>
                      <WaitForGoogleApisLoaded>
                        <FlueStatusMap
                          pageUrl={"/chimney-status"}
                          municipality={municipality}
                          data={rowsWithGeoLocations}
                          selected={selectedRowId}
                          width={width}
                          height={height}
                        />
                      </WaitForGoogleApisLoaded>
                    </GoogleMapApiProvider>
                  )}
                </FitContainerPane>
              </Paper>,
              <Paper
                key={2}
                elevation={3}
                sx={{
                  flex: 1,
                  minHeight: 0,
                  minWidth: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  padding: 1,
                }}
              >
                <SearchInput
                  fullWidth={true}
                  inputPlaceholder={t("Search by name, street, ID, or hashtag")}
                  defaultValue={search}
                  onValueChange={handleSearchChange}
                />
                {rows != null && (
                  <Box
                    sx={{
                      flexGrow: 1,
                      minHeight: 0,
                      minWidth: 0,
                    }}
                  >
                    <FlueStatusTable
                      query={query}
                      forceRefresh={searchSubmit}
                      rows={rows}
                      onRowsChange={handleRowsChange}
                      onSelectedRow={handleSelectedRow}
                      onViewRequested={handleViewRequested}
                      onEditRequested={handleEditRequested}
                    />
                  </Box>
                )}
              </Paper>,
            ]}
          />
        }
        disableResize={false}
        supportingPaneProps={{
          label: tFireflyDomain("Settings"),
          resizePanelProps: {
            minWidth: 240,
            minHeight: 240,
            horizontal: {
              style: {
                minWidth: 0,
                maxWidth: 350,
              },
            },
            vertical: {
              style: {
                minHeight: 0,
                maxHeight: 350,
              },
            },
          },
        }}
        supportingPane={
          <Paper
            elevation={paperElevation}
            sx={{
              flex: 1,
              padding: theme.spacing(1),
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Accordion variant={"elevation"} elevation={4} expanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {tFireflyDomain("Filter")}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 2,
                }}
              >
                <TextField
                  label={tFireflyDomain("Postal Code.short")}
                  helperText={tFireflyDomain("Filter on Postal Code")}
                  defaultValue={zipCode}
                  onKeyUp={handleFilterPostalCodeKeyUp}
                  size={componentSize}
                />
              </AccordionDetails>
            </Accordion>
          </Paper>
        }
      />
      <FlueDialog
        flueStatus={displayFlue}
        open={displayFlue != null}
        onGoPrevious={indexOfDisplayed > 0 ? handleGoPrevious : undefined}
        onGoNext={indexOfDisplayed < rows.length - 1 ? handleGoNext : undefined}
        onClose={handleDisplayFlueDialogClose}
        onUpdated={handleFlueUpdated}
        onDeleted={handleFlueDeleted}
      />
    </>
  );
};
