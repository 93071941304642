import React, { ReactNode, useEffect } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { Feature, Point } from "geojson";
import { useLatLngBounds } from "./useLatLngBounds";
import { FeatureMarker } from "./FeatureMarker";
import { ClusteredFeatureMarkers } from "./ClusteredFeatureMarkers";

export interface MapControllerProps<TFeature extends Feature<Point>> {
  features: Array<TFeature>;
  children?: React.ReactNode;
  renderInfoWindow: (props: {
    feature: TFeature;
    marker: google.maps.marker.AdvancedMarkerElement | null;
    onClose: () => void;
  }) => ReactNode;
}

export const MapController = <TFeature extends Feature<Point>>(
  props: MapControllerProps<TFeature>
) => {
  const { features } = props;
  const map = useMap();
  const latLngBounds = useLatLngBounds(features);
  useEffect(() => {
    if (latLngBounds != null) {
      map?.fitBounds(latLngBounds);
    }
  }, [latLngBounds, map]);
  return (
    <ClusteredFeatureMarkers
      features={features}
      render={(args) => (
        <FeatureMarker
          key={args.feature.id}
          feature={args.feature}
          initialInfoWindowOpen={args.openMarkerInfoWindow}
          setMarkerRef={args.setMarkerRef}
          onInfoWindowOpen={args.onMarkerInfoWindowOpen}
          renderInfoWindow={props.renderInfoWindow}
        />
      )}
    />
  );
};
