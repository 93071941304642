import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { LatLng } from "./LatLng";
import { Marker as ClustererMarker } from "@googlemaps/markerclusterer";
import { Feature, Point } from "geojson";
import { IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export interface FeatureMarkerProps<TFeature extends Feature<Point>> {
  feature: TFeature;
  initialInfoWindowOpen?: boolean;
  onInfoWindowOpen?: (open: boolean, feature: TFeature) => void;
  setMarkerRef?: (marker: ClustererMarker | null, key: string) => void;
  renderInfoWindow?: (props: {
    feature: TFeature;
    marker: google.maps.marker.AdvancedMarkerElement | null;
    onClose: () => void;
  }) => ReactNode;
}

export const FeatureMarker = <TFeature extends Feature<Point>>(
  props: FeatureMarkerProps<TFeature>
) => {
  const { feature, setMarkerRef, initialInfoWindowOpen, onInfoWindowOpen } =
    props;
  const [infoWindowOpen, setInfoWindowOpen] = useState(initialInfoWindowOpen);
  const [setMarker, marker] = useAdvancedMarkerRef();
  const ref = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement) => {
      if (feature.id == null) {
        throw new IllegalArgumentError("Feature must have an id");
      }
      setMarkerRef?.(marker, feature.id?.toString());
      setMarker(marker);
    },
    [feature.id, setMarkerRef, setMarker]
  );
  const position = LatLng.fromFeature(feature);

  useEffect(() => {
    setInfoWindowOpen(initialInfoWindowOpen);
  }, [initialInfoWindowOpen]);

  const handleMarkerClick = useCallback(() => {
    if (props.renderInfoWindow != null) {
      if (onInfoWindowOpen != null) {
        onInfoWindowOpen(!infoWindowOpen, feature);
      } else {
        setInfoWindowOpen((prevState) => !prevState);
      }
    }
  }, [feature, infoWindowOpen, onInfoWindowOpen, props.renderInfoWindow]);

  const handleInfoWindowClose = useCallback(() => {
    if (onInfoWindowOpen != null) {
      onInfoWindowOpen(false, feature);
    } else {
      setInfoWindowOpen(false);
    }
  }, [feature, onInfoWindowOpen]);

  return (
    <>
      <AdvancedMarker
        ref={ref}
        onClick={handleMarkerClick}
        position={position}
      />
      {infoWindowOpen &&
        props.renderInfoWindow != null &&
        props.renderInfoWindow({
          feature: feature,
          marker: marker,
          onClose: handleInfoWindowClose,
        })}
    </>
  );
};
