import React, { FC } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { MathUtils, notUndef } from "@airmont/shared/ts/utils/core";
import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { useTranslation } from "react-i18next";
import { FlueBreadcrumbs } from "@airmont/firefly/shared/ts/domain";

export interface FlueStatusInfoWindowCardProps {
  flueStatus: FlueStatusDto;
  onView?: (feature: FlueStatusDto) => void;
  onClose?: () => void;
}

export const FlueStatusInfoWindowCard: FC<FlueStatusInfoWindowCardProps> = (
  props
) => {
  const { t } = useTranslation("firefly-shared-ts-domain");
  const theme = useTheme();
  const flueStatus = props.flueStatus;

  return (
    <Card elevation={0} sx={{ backgroundColor: "transparent" }}>
      <CardHeader
        title={
          <FlueBreadcrumbs
            municipality={props.flueStatus.municipality}
            postalAddress={props.flueStatus.postalAddress}
            streetAddress={props.flueStatus.streetAddress}
            flue={props.flueStatus.flue}
            hideMunicipality
            hidePostalAddress
          />
        }
        subheader={
          <Box sx={{ whiteSpace: "nowrap" }}>
            {`${props.flueStatus.postalAddress?.code ?? ""} ${
              props.flueStatus.postalAddress?.place ?? ""
            }`}
          </Box>
        }
        sx={{ padding: theme.spacing(0.5) }}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ padding: "4px" }}>
          <Typography sx={{ fontSize: "12px" }}>
            {t("Soot Index")}
            {": "}
            {notUndef(flueStatus.metricValuesSinceSweep?.sootIndex, (it) =>
              MathUtils.round(it)
            )}
          </Typography>
        </CardContent>
        <CardActions sx={{ pt: 0 }}>
          <Button onClick={() => props.onView?.(flueStatus)} size={"small"}>
            {t("Show")}
          </Button>
          {props.onClose != null && (
            <Button onClick={props.onClose} size={"small"}>
              {t("Close")}
            </Button>
          )}
        </CardActions>
      </Box>
    </Card>
  );
};
